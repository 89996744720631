import React, { Fragment } from "react"
import { Link } from "gatsby"

import Sub from "~layout/sub"

const crumbs = [
    { text: "Bett 2023", to: "/bett2023" },
]

const Bett2023Page = () => {
    let content = (
        <Fragment>
          {/* <p><img src={require('~images/2023-bett.png').default} className="top-logo-sm img-responsive margin-auto branding-logo block" alt="Bett Show 2023" /></p> */}
          <br/>
           
           <p className="text-center text-initial">
           <b>Welcome to our CommonTown Bett page.</b>
            <br/>Here you will find information about our products.<br/>You may click to download a brochure or visit a product website / page to browse.</p>
<br/>

<div className="brochure-wrapper">
<table className="brochure-listing">
<tr>
  <td>
  <a href="/bett2023pdf" title="Download Bett 2023 Brochure.pdf" target="_blank">
    <img src={require('~images/bett2023/ctxbett2023.png').default} className="img-responsive margin-auto branding-logo block img-initial" alt="Bett 2023 Brochure" />
    </a>
    </td>
  <td><p className="text-center">
    <a className="btn-download" href="/bett2023pdf" title="Download Bett 2023 Brochure" target="_blank">
            CommonTown Bett Brochure
    </a>
 </p></td>
</tr>
<tr>
  <td><a id="brochure-dudu"></a>
  <a href="/dudupdf" title="Download Dudu Chinese Brochure" target="_blank">
  <img src={require('~images/bett2023/dudu.png').default} className="img-responsive margin-auto branding-logo block logo-s" alt="Dudu Chinese " />
  </a>
 </td>
  <td> <p className="text-center">
    <a className="btn-download" href="/dudupdf" title="Download Dudu Chinese Brochure" target="_blank">
            Dudu Chinese Brochure
    </a>
 </p></td>
</tr>
<tr>
<td><a id="brochure-readin"></a>
<a href="/readinpdf" title="Download Readin English Brochure" target="_blank">
<img src={require('~images/bett2023/readin.png').default} className="img-responsive margin-auto branding-logo block  logo-s" alt="Readin English" />
</a>
  </td>
<td>
  <p className="text-center">
    <a className="btn-download" href="/readinpdf" title="Download Readin English Brochure" target="_blank">
            Readin English Brochure
    </a>
 </p></td>
</tr>
<tr>
<td><a id="brochure-qoqolo"></a>
<a href="https://www.qoqolo.com" target="_blank" title="Download Qoqolo Brochure">
<img src={require('~images/bett2023/qoqolo logo.png').default} className="img-responsive margin-auto branding-logo block logo-s" alt="Qoqolo" />
</a>
  </td>
  <td><p className="text-center">
    <a className="btn-share" href="https://www.qoqolo.com" target="_blank" title="Visit Qoqolo Website">
            Visit Qoqolo Website
    </a>
 </p></td>

</tr>

</table>
<table className="brochure-listing">
<tr>
  <td> <a id="brochure-sl"></a>
  <a href="https://www.shinglee.com.sg/" target="_blank" title="Visit Shinglee Website"><img src={require('~images/bett2023/shing_lee_logo_50.png').default} className="img-responsive margin-auto branding-logo block logo-xs" alt="Shinglee" />
  </a>
  </td>
  <td><p className="text-center">
  <a className="btn-share" href="https://www.shinglee.com.sg/" target="_blank" title="Visit Shinglee Website">
            Visit Shinglee Website
    </a>
   
 </p></td>
</tr>
<tr>
  <td> <a id="brochure-sw"></a>
  <a href="https://www.stemwerkz.org/" target="_blank" title="Visit STEMWerkz Website"><img src={require('~images/bett2023/stemwerkz logo.png').default} className="img-responsive margin-auto branding-logo block img-initial logo-s" alt="STEMWerkz" />
  </a>
  </td>
  <td><p className="text-center">
  <a className="btn-share" href="https://www.stemwerkz.org/" target="_blank" title="Visit STEMWerkz Website">
            Visit STEMWerkz Website
    </a>
   
 </p></td>
</tr>
<tr>
  <td> <a id="brochure-ca"></a>
  
  <a href="/products/commonacademy" target="_blank" title="Visit CommonAcademy Product Page">
 <img src={require('~images/bett2023/CommonAcademy logo.png').default} className="img-responsive margin-auto branding-logo block logo-s" alt="CommonAcademy" /> 
    </a>
  </td>
  <td><p className="text-center">
  <a className="btn-share" href="/products/commonacademy" target="_blank" title="Visit CommonAcademy Product Page">
  Visit CommonAcademy Product Page
    </a>
   
 </p></td>
</tr>
</table>
</div>
<br/>
<table className="full-table">
  <tr>
<td className="content-partners">
      <h4 className="text-center">Our Products</h4>
      <p>
      <img src={require('~images/bett2023/content partners/Logo-03.png').default} className="img-responsive content-partner-logo logo-xs" alt="Shing Lee Education" /> 
      <img src={require('~images/bett2023/content partners/Logo-02.png').default} className="img-responsive content-partner-logo" alt="SingEd" /> 
      <img src={require('~images/bett2023/content partners/Logo-01.png').default} className="img-responsive content-partner-logo" alt="SCCL" /> 
      <img src={require('~images/bett2023/content partners/Logo-05.png').default} className="img-responsive content-partner-logo logo-xs" alt="Dudu Chinese" /> 
      <img src={require('~images/bett2023/content partners/Logo-06.png').default} className="img-responsive content-partner-logo logo-xs" alt="Readin English" /> 
      <img src={require('~images/bett2023/content partners/Logo-04.png').default} className="img-responsive content-partner-logo logo-xs" alt="Amdon" /> 
      </p>
</td>
  </tr>
<tr>
  <td>
  <div className="margin-auto">
  <h5 className="contactus-info">Contact Us</h5>
  <p>For more information or sales enquiries, email us at <a href="mailto:marveric_goh@commontown.com" alt="" >marveric_goh@commontown.com</a> or call us at <a href="tel:+6581886875" alt="">+65 8188 6875</a>.</p>
  </div>
  </td>
</tr>
</table>

        </Fragment>
    )

    return (
        <Sub title="Bett 2023" content={content} crumbs={crumbs} />
    )
}

export default Bett2023Page
